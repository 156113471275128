import React from 'react';

const Warning = () => (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.84375 12.4155H11.8159C12.7681 12.4155 13.3647 11.73 13.3647 10.8667C13.3647 10.6064 13.3013 10.3462 13.1616 10.105L8.16602 1.18652C7.88037 0.672363 7.35352 0.399414 6.83301 0.399414C6.30615 0.399414 5.7793 0.672363 5.49365 1.18652L0.504395 10.1113C0.371094 10.3525 0.294922 10.6064 0.294922 10.8667C0.294922 11.73 0.891602 12.4155 1.84375 12.4155ZM6.83301 8.17529C6.50293 8.17529 6.3252 7.98486 6.31885 7.64844L6.22998 4.19531C6.22363 3.85889 6.47119 3.61768 6.82666 3.61768C7.17578 3.61768 7.43604 3.86523 7.42969 4.20166L7.34082 7.64844C7.33447 7.99121 7.15039 8.17529 6.83301 8.17529ZM6.83301 10.3018C6.4585 10.3018 6.12842 9.99707 6.12842 9.62256C6.12842 9.2417 6.45215 8.93701 6.83301 8.93701C7.22021 8.93701 7.54395 9.23535 7.54395 9.62256C7.54395 10.0034 7.21387 10.3018 6.83301 10.3018Z"
      fill="white"
    />
  </svg>
);

export default Warning;
