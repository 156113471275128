import React from 'react';

const Backward60 = () => (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M18.7696 26.4439C18.4129 26.4417 18.0687 26.3834 17.7369 26.269C17.4074 26.1546 17.1117 25.9686 16.8498 25.7112C16.588 25.4538 16.3803 25.1128 16.2268 24.6881C16.0756 24.2635 16 23.742 16 23.1238C16.0023 22.5561 16.0688 22.049 16.1998 21.6023C16.3329 21.1535 16.5225 20.7728 16.7686 20.4604C17.0169 20.148 17.3137 19.9103 17.659 19.7475C18.0044 19.5825 18.3915 19.5 18.8203 19.5C19.2831 19.5 19.6916 19.588 20.046 19.764C20.4004 19.9378 20.6848 20.1744 20.8992 20.4736C21.1159 20.7728 21.2468 21.1084 21.292 21.4802H19.8462C19.7898 21.2448 19.6713 21.06 19.4907 20.9257C19.3102 20.7915 19.0867 20.7244 18.8203 20.7244C18.3689 20.7244 18.0258 20.9158 17.7911 21.2987C17.5586 21.6815 17.4401 22.2019 17.4356 22.8597H17.483C17.5868 22.6595 17.7267 22.489 17.9028 22.3482C18.0811 22.2052 18.2831 22.0963 18.5089 22.0215C18.7368 21.9444 18.9772 21.9059 19.23 21.9059C19.6408 21.9059 20.0065 22.0006 20.327 22.1898C20.6475 22.3768 20.9003 22.6342 21.0854 22.962C21.2705 23.2899 21.3631 23.665 21.3631 24.0875C21.3631 24.5451 21.2536 24.9521 21.0346 25.3086C20.818 25.665 20.5144 25.9444 20.1239 26.1469C19.7356 26.3471 19.2842 26.4461 18.7696 26.4439ZM18.7628 25.3218C18.9885 25.3218 19.1905 25.269 19.3688 25.1634C19.5472 25.0578 19.6871 24.9147 19.7887 24.7343C19.8903 24.5539 19.941 24.3515 19.941 24.1271C19.941 23.9026 19.8903 23.7013 19.7887 23.5231C19.6894 23.3449 19.5517 23.203 19.3756 23.0974C19.1996 22.9917 18.9987 22.9389 18.7729 22.9389C18.6037 22.9389 18.4468 22.9697 18.3023 23.0314C18.1601 23.093 18.0348 23.1788 17.9265 23.2888C17.8204 23.3988 17.7369 23.5264 17.676 23.6716C17.615 23.8146 17.5845 23.9675 17.5845 24.1304C17.5845 24.3482 17.6353 24.5473 17.7369 24.7277C17.8407 24.9081 17.9807 25.0523 18.1567 25.1601C18.3351 25.2679 18.5371 25.3218 18.7628 25.3218Z"
        fill="white"
      />
      <path
        d="M25.1999 26.5C24.6176 26.4978 24.1165 26.3581 23.6966 26.0809C23.2791 25.8036 22.9574 25.4021 22.7317 24.8762C22.5082 24.3504 22.3976 23.7178 22.3999 22.9785C22.3999 22.2415 22.5116 21.6133 22.7351 21.0941C22.9608 20.5748 23.2825 20.1799 23.7 19.9092C24.1199 19.6364 24.6198 19.5 25.1999 19.5C25.78 19.5 26.2789 19.6364 26.6964 19.9092C27.1163 20.1821 27.4391 20.5781 27.6648 21.0974C27.8905 21.6144 28.0022 22.2415 28 22.9785C28 23.72 27.8871 24.3537 27.6614 24.8795C27.4379 25.4054 27.1174 25.8069 26.6998 26.0842C26.2822 26.3614 25.7823 26.5 25.1999 26.5ZM25.1999 25.3152C25.5972 25.3152 25.9143 25.1205 26.1513 24.731C26.3883 24.3416 26.5057 23.7574 26.5035 22.9785C26.5035 22.4659 26.4493 22.0391 26.3409 21.698C26.2348 21.357 26.0836 21.1007 25.8872 20.929C25.6931 20.7574 25.464 20.6716 25.1999 20.6716C24.8049 20.6716 24.4889 20.8641 24.2519 21.2492C24.0149 21.6342 23.8953 22.2107 23.893 22.9785C23.893 23.4978 23.9461 23.9312 24.0522 24.2789C24.1605 24.6243 24.3129 24.8839 24.5092 25.0578C24.7056 25.2294 24.9358 25.3152 25.1999 25.3152Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.163 13.2505V11.1218C22.163 10.689 21.6506 10.4606 21.3288 10.7499L17.6555 14.0519C17.4257 14.2585 17.4364 14.6219 17.6779 14.8146L21.3512 17.7453C21.6787 18.0066 22.163 17.7734 22.163 17.3544V14.9899C26.6501 15.0767 30.2609 18.7413 30.2609 23.2492C30.2609 27.8115 26.5624 31.51 22 31.51C17.4376 31.51 13.7391 27.8115 13.7391 23.2492C13.7391 22.7689 13.3498 22.3796 12.8696 22.3796C12.3893 22.3796 12 22.7689 12 23.2492C12 28.772 16.4772 33.2492 22 33.2492C27.5228 33.2492 32 28.772 32 23.2492C32 17.7808 27.6107 13.3375 22.163 13.2505Z"
        fill="white"
      />{' '}
    </g>
  </svg>
);

export default Backward60;
