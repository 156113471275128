import React from 'react';

const Pause = () => (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 32C13 32.5523 13.4477 33 14 33H18.16C18.7123 33 19.16 32.5523 19.16 32V13C19.16 12.4477 18.7123 12 18.16 12H14C13.4477 12 13 12.4477 13 13V32ZM26.32 12C25.7677 12 25.32 12.4477 25.32 13V32C25.32 32.5523 25.7677 33 26.32 33H30.48C31.0323 33 31.48 32.5523 31.48 32V13C31.48 12.4477 31.0323 12 30.48 12H26.32Z"
      fill="white"
    />
  </svg>
);

export default Pause;
