import React from 'react';

const Recorded = () => (
  <svg
    width="18"
    height="9"
    viewBox="0 0 18 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.02778 6.91667C5.41872 6.91667 6.5463 5.72276 6.5463 4.25C6.5463 2.77724 5.41872 1.58333 4.02778 1.58333C2.63684 1.58333 1.50926 2.77724 1.50926 4.25C1.50926 5.72276 2.63684 6.91667 4.02778 6.91667ZM6.84362 6.91667C7.44181 6.20899 7.80556 5.27447 7.80556 4.25C7.80556 2.04086 6.11419 0.25 4.02778 0.25C1.94137 0.25 0.25 2.04086 0.25 4.25C0.25 6.45914 1.94137 8.25 4.02778 8.25H13.4722C15.5586 8.25 17.25 6.45914 17.25 4.25C17.25 2.04086 15.5586 0.25 13.4722 0.25C11.3858 0.25 9.69444 2.04086 9.69444 4.25C9.69444 5.27447 10.0582 6.20899 10.6564 6.91667H6.84362ZM13.4722 6.91667C14.8632 6.91667 15.9907 5.72276 15.9907 4.25C15.9907 2.77724 14.8632 1.58333 13.4722 1.58333C12.0813 1.58333 10.9537 2.77724 10.9537 4.25C10.9537 5.72276 12.0813 6.91667 13.4722 6.91667Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.02778 0.5C2.09271 0.5 0.5 2.16528 0.5 4.25C0.5 6.33472 2.09271 8 4.02778 8H13.4722C15.4073 8 17 6.33472 17 4.25C17 2.16528 15.4073 0.5 13.4722 0.5C11.5372 0.5 9.94444 2.16528 9.94444 4.25C9.94444 5.21473 10.2868 6.09222 10.8473 6.75528L11.1951 7.16667H6.30494L6.65269 6.75528C7.21317 6.09222 7.55556 5.21473 7.55556 4.25C7.55556 2.16528 5.96284 0.5 4.02778 0.5ZM0 4.25C0 1.91645 1.79003 0 4.02778 0C6.26553 0 8.05556 1.91645 8.05556 4.25C8.05556 5.14655 7.79224 5.97973 7.34152 6.66667H10.1585C9.70776 5.97973 9.44444 5.14655 9.44444 4.25C9.44444 1.91645 11.2345 0 13.4722 0C15.71 0 17.5 1.91645 17.5 4.25C17.5 6.58355 15.71 8.5 13.4722 8.5H4.02778C1.79003 8.5 0 6.58355 0 4.25ZM4.02778 1.83333C2.78818 1.83333 1.75926 2.90166 1.75926 4.25C1.75926 5.59834 2.78818 6.66667 4.02778 6.66667C5.26737 6.66667 6.2963 5.59834 6.2963 4.25C6.2963 2.90166 5.26737 1.83333 4.02778 1.83333ZM1.25926 4.25C1.25926 2.65282 2.4855 1.33333 4.02778 1.33333C5.57006 1.33333 6.7963 2.65282 6.7963 4.25C6.7963 5.84718 5.57006 7.16667 4.02778 7.16667C2.4855 7.16667 1.25926 5.84718 1.25926 4.25ZM13.4722 1.83333C12.2326 1.83333 11.2037 2.90166 11.2037 4.25C11.2037 5.59834 12.2326 6.66667 13.4722 6.66667C14.7118 6.66667 15.7407 5.59834 15.7407 4.25C15.7407 2.90166 14.7118 1.83333 13.4722 1.83333ZM10.7037 4.25C10.7037 2.65282 11.9299 1.33333 13.4722 1.33333C15.0145 1.33333 16.2407 2.65282 16.2407 4.25C16.2407 5.84718 15.0145 7.16667 13.4722 7.16667C11.9299 7.16667 10.7037 5.84718 10.7037 4.25Z"
      fill="black"
    />
  </svg>
);

export default Recorded;
